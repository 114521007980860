import { getItem, setItem } from './storage';
import { ListOpt, Opt, OptType, MapOpt, OptT, OptFilter, ColumnId } from '/@shared/types/list';

type FilterListCountMapperMapFn = (
  { value, count, opt }: { value: string; count: number; opt: Opt },
  dependencies: any[],
) => [number | string, { name: string; count: number }] | null;

export function filterListCountMapper(
  countFn: any,
  mapFn: FilterListCountMapperMapFn,
): (any) => Promise<Map<string | number, { name: string; count: number | null }>> {
  return ({ opt, column, columns }) => {
    return Promise.all([
      countFn({
        propName: column.filterKey,
        propId: column.filterKey,
        opt,
        columns: columns.filter(({ id }) => id !== column.id),
      }),
      ...(column.dependencies ?? []),
    ]).then(async ([counts, ...dependencies]) => {
      const resolvedDependencies = await Promise.all(
        dependencies.map((dep) => (typeof dep === 'function' ? dep() : Promise.resolve(dep))),
      );
      const countsMapped = new Map(
        [...counts]
          .map(([value, count]) => mapFn({ value, count, opt }, resolvedDependencies))
          .filter((x) => x != null),
      );
      const uncountsMapped =
        resolvedDependencies.length > 0
          ? [...resolvedDependencies[0].keys()]
              .filter((id) => !countsMapped.has(id))
              .map((value) => mapFn({ value, count: 0, opt }, resolvedDependencies))
              .filter((x) => x != null)
          : [];

      return new Map([...countsMapped, ...uncountsMapped] as [
        string | number,
        { name: string; count: number },
      ][]);
    });
  };
}

export function newListOpt(opt?: Partial<ListOpt>): ListOpt {
  return {
    type: OptType.List,
    tenantId: opt?.tenantId ?? null,
    sortDesc: opt?.sortDesc ?? false,
    sortBy: opt?.sortBy ?? '',
    offset: opt?.offset ?? 0,
    limit: opt?.limit ?? 100,
    search: opt?.search ?? '',
    filters: opt?.filters ?? [],
    columns: opt?.columns ?? [],
    marked: opt?.marked ?? [],
    createdAt: opt?.createdAt ?? new Date(),
    version: 5,
  };
}

export function newMapOpt(opt?: Partial<MapOpt>): Partial<MapOpt> {
  return {
    type: OptType.Map,
    tenantId: opt?.tenantId ?? null,
    coordinates: opt?.coordinates ?? [12, 60],
    zoom: opt?.zoom ?? 0, // Do not change default: Several places relies on this being `0` to determine if the map has been modified.
    search: opt?.search ?? '',
    filters: opt?.filters ?? [],
    columns: opt?.columns ?? [],
    marked: opt?.marked ?? [],
    createdAt: opt?.createdAt ?? new Date(),
    version: 5,
    disableReposition: false,
  };
}

export function optFromLocalStorage<T extends OptType>(
  type: T,
  key: string,
  defaults?: Partial<OptT<T>>,
): OptT<T> {
  const currentVersion = 5;
  const ls = getItem(key, { useUser: true });
  const data = {
    ...defaults,
    ...(ls?.version === currentVersion ? ls : {}),
  };

  switch (type) {
    case OptType.List:
      return newListOpt(data) as OptT<T>;

    case OptType.Map:
      return newMapOpt(data) as OptT<T>;
  }
}

export function optToLocalStorage(key: string, opt: Opt): void {
  const oldOpt = getItem(key, { useUser: true });
  setItem(
    key,
    {
      ...oldOpt,
      ...opt,
    },
    { useUser: true },
  );
}

export function getFilterValue(
  filters: Array<OptFilter>,
  columnId: ColumnId,
): Array<number | string> {
  return filters.find(({ columnId: id }) => id === columnId)?.values ?? [];
}

export function hasFilterValue(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  value: number | string,
): boolean {
  return filters.find(({ columnId: id }) => id === columnId)?.values.includes(value) ?? false;
}

export interface FilterOptions {
  isHidden?: boolean;
}

export function addFilterValues(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  values: Array<number | string>,
  { isHidden = false }: FilterOptions = {},
): Array<OptFilter> {
  const filter = filters.find(({ columnId: id }) => id === columnId);
  const otherFilters = filters.filter(({ columnId: id }) => id !== columnId);
  const newValues = values.filter((x) =>
    x != null && typeof x === 'string' ? x.length > 0 : true,
  );

  return newValues.length > 0
    ? [
        ...otherFilters,
        {
          columnId,
          values:
            filter != null
              ? [...newValues, ...filter.values.filter((value) => !values.includes(value))]
              : newValues,
          isHidden,
        },
      ]
    : otherFilters;
}

export function replaceFilterValues(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  values: Array<number | string>,
  { isHidden = false }: FilterOptions = {},
): Array<OptFilter> {
  const otherFilters = filters.filter(({ columnId: id }) => id !== columnId);
  const newValues =
    values?.filter((x) => (x != null && typeof x === 'string' ? x.length > 0 : true)) || [];

  return newValues.length > 0
    ? [...otherFilters, { columnId, values: newValues, isHidden }]
    : otherFilters;
}

export function toggleFilterValue(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  value: number | string,
  options?: FilterOptions,
): Array<OptFilter> {
  const filter = filters.find(({ columnId: id }) => id === columnId);
  const hasValue = filter?.values.includes(value) ?? false;

  return hasValue
    ? removeFilterValues(filters, columnId, [value])
    : addFilterValues(filters, columnId, [value], options);
}

export function removeFilterValues(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  values: Array<number | string>,
): Array<OptFilter> {
  const filter = filters.find(({ columnId: id }) => id === columnId);
  const otherFilters = filters.filter(({ columnId: id }) => id !== columnId);
  const newValues = filter?.values.filter((value) => !values.includes(value)) ?? [];

  return newValues.length > 0
    ? [...otherFilters, { columnId, values: newValues, isHidden: filter?.isHidden ?? false }]
    : otherFilters;
}

export function clearFilter(filters: Array<OptFilter>, columnId: ColumnId): Array<OptFilter> {
  return filters.filter(({ columnId: id }) => id !== columnId);
}
