import { Geometry, GeometryType } from '/@shared/types/geometry';
import { FeatureTypes } from '/@shared/types/map';
import { convertMunicipality } from '/@shared/tools/general-utils';

const urlBase = 'https://maps.googleapis.com/maps/api/geocode/json';
const urlKey = 'key=AIzaSyCcOmoOrSLOIdgyRuZGBwsSlY-59_ZDDuU';
const urlBaseKartverket = 'https://ws.geonorge.no/adresser/v1';

type MapCoordinates = {
  lat: number;
  lng: number;
};

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export function mapAddress(address) {
  return {
    lat: address.representasjonspunkt.lat,
    lng: address.representasjonspunkt.lon,
    municipality: convertMunicipality(address.kommunenavn),
    municipalityNumber: address.kommunenummer,
    address: address.adressetekst,
    postalNumber: address.postnummer,
    postalPlace: convertMunicipality(address.poststed),
  };
}

export function distance(
  { lat: lat1, lng: lng1 }: MapCoordinates,
  { lat: lat2, lng: lng2 }: MapCoordinates,
) {
  const { PI, sin, cos, atan2, sqrt } = Math;
  const deg2rad = (deg) => deg * (PI / 180);

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dlng = deg2rad(lng2 - lng1);
  const a =
    sin(dLat / 2) * sin(dLat / 2) +
    cos(deg2rad(lat1)) * cos(deg2rad(lat2)) * sin(dlng / 2) * sin(dlng / 2);
  const c = 2 * atan2(sqrt(a), sqrt(1 - a));

  return R * c;
}

export function searchAddress({ searchText, municipality = '', postalPlace = '' }) {
  const queryMunicipality = `${municipality ? `&kommunenavn="${municipality}` : ''}`;
  const queryPostalPlace = `${postalPlace ? `&poststed="${postalPlace}` : ''}`;

  // TODO: Endre fuzzysearch til et bedre system da den f.eks ikke plukker opp "Torgveien 3"
  const url = encodeURI(
    `${urlBaseKartverket}/sok?sok=${searchText}*${queryMunicipality}${queryPostalPlace}`,
  );
  return fetch(url)
    .then((response) => response.json())
    .then(({ adresser }) => {
      if (Array.isArray(adresser)) {
        return Array.from(adresser, mapAddress);
      } else if (adresser === undefined) {
        return [];
      } else {
        return [mapAddress(adresser)];
      }
    });
}

export function searchLatLng({ lat, lng }: MapCoordinates) {
  const url = encodeURI(`${urlBaseKartverket}/punktsok?lat=${lat}&lon=${lng}&radius=1000`);
  return fetch(url)
    .then((response) => response.json())
    .then(({ adresser }) => {
      if (Array.isArray(adresser)) {
        return Array.from(adresser, mapAddress).sort((a, b) =>
          distance(a, { lat, lng }) > distance(b, { lat, lng }) ? 1 : -1,
        );
      } else if (adresser === undefined) {
        return [];
      } else {
        return [mapAddress(adresser)];
      }
    })
    .catch(() => []);
}

export async function searchLatLngMap({ lat, lng }: MapCoordinates) {
  try {
    const url = encodeURI(`${urlBaseKartverket}/punktsok?lat=${lat}&lon=${lng}&radius=1000`);
    const response = await fetch(url);
    if (response.ok) {
      const { adresser } = await response.json();
      if (Array.isArray(adresser)) {
        return Array.from(adresser, mapAddress).sort((a, b) =>
          distance(a, { lat, lng }) > distance(b, { lat, lng }) ? 1 : -1,
        );
      } else if (adresser === undefined) {
        return [];
      } else {
        return [mapAddress(adresser)];
      }
    } else {
      if (response.status === 400) throw new Error('Sjekk at koordinatene er korrekt utfylt.');
      if (response.status === 500) throw new Error('Ta kontakt hvis problemet vedvarer.');
      throw new Error(String(`Feilkode: ${response.status}`));
    }
  } catch (error) {
    throw new Error(`Det oppstod en feil med søket. ${getErrorMessage(error)}`);
  }
}

export function find(results, type) {
  const component = results[0].address_components.find((component) => {
    return component.types.indexOf(type) > -1;
  });

  if (!component) return '';

  return component.long_name;
}

export function convertToLatLng(address, municipality) {
  const url = encodeURI(`${urlBase}?address=${address}, ${municipality}, Norway&${urlKey}`);

  // TODO: Use axios
  // FIXME: Axios fails the preflight because of CORS
  return fetch(url)
    .then((response) => response.json())
    .then((response) => {
      const results = response.results;

      if (!results || results.length === 0) return;
      return results[0].geometry.location;
    })
    .catch((error) => {});
}

export function getLocationHelper({ lat, lng }: MapCoordinates) {
  if (!lat || !lng) {
    throw new Error(`Invalid latLng '${lat}, ${lng}' in getLocatioHelper`);
  }

  const urlLatLng = `latlng=${lat},${lng}`;
  const url = encodeURI(urlBase + '?' + urlLatLng + '&' + urlKey);

  // TODO: Use axios
  // FIXME: Axios fails the preflight because of CORS

  return fetch(url)
    .then((response) => response.json())
    .then((response) => {
      const results = response.results;

      if (!results || results.length === 0) return null;

      let streetNumber = find(results, 'street_number');
      let streetName = find(results, 'route');
      let municipality = find(results, 'administrative_area_level_2');
      let postalNumber = find(results, 'postal_code');
      let postalPlace = find(results, 'postal_town');
      let address = streetName + ' ' + streetNumber;

      return {
        address,
        municipality,
        latitude: lat,
        longitude: lng,
        postalPlace,
        postalNumber,
      };
    });
}

type Subscriber = (coordinates: GeolocationCoordinates) => void;
const subscribers: Set<Subscriber> = new Set();
let lastCoords: GeolocationCoordinates;
let watcher: number;

function start() {
  if (watcher != null) stop();

  watcher = watchPosition(
    (coords) => {
      lastCoords = coords;
      subscribers.forEach((fn) => fn(coords));
    },
    { enableHighAccuracy: true },
  );
}

function stop() {
  if (watcher == null) return;

  navigator.geolocation.clearWatch(watcher);

  watcher = null;
}

interface WatchGeoPositionOptions {
  /** If true, don't prompt for geolocation */
  silent: boolean;
}

export function watchGeoPosition(subscriber: Subscriber, options?: WatchGeoPositionOptions) {
  subscribers.add(subscriber);

  if (watcher == null) {
    if (options?.silent && 'permissions' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') start();
      });
    } else {
      start();
    }
  }

  if (lastCoords != null) subscriber(lastCoords);

  return {
    cancel() {
      subscribers.delete(subscriber);
      if (subscribers.size === 0) stop();
    },
  };
}

export function latLngFromGeometry(geometry: Geometry): [number, number] {
  switch (geometry.type) {
    case GeometryType.Point:
      return geometry.coordinates;

    case GeometryType.LineString:
      return geometry.coordinates[0];

    case GeometryType.Polygon:
      return geometry.coordinates[0][0];
  }
}

export function featureTypeFromGeometry(geometry: Geometry): FeatureTypes {
  switch (geometry.type) {
    case GeometryType.Point:
      return FeatureTypes.Point;

    case GeometryType.LineString:
      return FeatureTypes.Cable;

    case GeometryType.Polygon:
      return FeatureTypes.Areas;
  }
}
